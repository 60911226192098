<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12">
            <h2 class="text-h5 font-weight-bold mb-0">Crear Encabezado por territorio</h2>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="8">
            <!-- autocomplete for territory -->
            <v-autocomplete
                v-if="$can('superadmin')"
                v-model="territory"
                :error-messages="validationErrors.territory"
                :items="dataSource.data.Territorio"
                dense
                item-text="descripcion"
                item-value="territorio_id"
                label="Territorio"
                solo
                @change="getHeaderLines()"
            ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-btn color="primary" @click="addLine">
              <v-icon>mdi-plus</v-icon>
              <span>Agregar linea</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-for="(line,i) in $v.headers.$each.$iter" :key="i" class="mt-1 mb-1" cols="12">
            <v-text-field
                v-model="headers[i]"
                :append-outer-icon="'mdi-close-circle'"
                :label="`Linea ${Number(i)+1}`"
                dense
                solo
                @click:append-outer="removeLine(i)"
            ></v-text-field>
            <span v-if="!line.maxLength" class="red--text">La longitud maxima es de 180 caracteres</span>
            <span v-if="!line.required" class="red--text">La linea de encabezado no puede ser en blanco</span>
          </v-col>
          <v-col cols="6">
            <v-btn color="primary" @click="save">
              <v-icon>mdi-content-save</v-icon>
              <span>Guardar</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {mapState} from "vuex";
import {maxLength, required} from "vuelidate/lib/validators";
import {getHeaderLines, storeHeaderLines} from "@/services/SelfJustice";
import Swal from "sweetalert2";

export default {
  name: "HeaderLines.vue",
  data() {
    return {
      territory: null,
      headers: []
    }
  },
  created() {
    this.territory = this.user.territory_id;
    this.getHeaderLines();
  },
  validations() {
    return {
      territory: {
        required
      },
      headers: {
        required,
        maxLength: maxLength(5),
        $each: {
          required,
          maxLength: maxLength(180)
        }
      }
    }
  },
  computed: {
    ...mapState(['dataSource', 'user']),
    validationErrors() {
      return {
        territory: [
          ...[
            !this.$v.territory.required && this.$v.territory.$dirty
                ? "Este campo es requerido"
                : "",
          ],
        ],
        headers: [
          ...[
            !this.$v.headers.required
                ? "Este campo es requerido"
                : "",
          ],
          ...[
            !this.$v.headers.maxLength
                ? "Este campo no puede tener mas de 5 lineas"
                : "",
          ],
        ]
      }
    }
  },
  methods: {
    addLine() {
      this.$v.$touch();
      if (!this.$v.headers.maxLength) return;
      this.headers.push('')
    },
    removeLine(index) {
      this.headers.splice(index, 1);
    },
    async save() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.showLoader();
        await storeHeaderLines({
          header_lines: this.headers
        }, this.territory);
        this.hideLoader();
        Swal.fire('Base de datos', 'Encabezado guardado correctamente', 'success');
      } catch (e) {
        this.hideLoader()
        console.error(e);
        Swal.fire('Error', 'Ha ocurrido un error al guardar', 'error')
      }

    },
    async getHeaderLines() {
      if (this.territory === null) return;
      try {
        this.showLoader();
        const response = await getHeaderLines(this.territory);
        this.hideLoader();
        this.headers = response.data.map(x => x.line);
      } catch (e) {
        this.hideLoader()
        console.error(e);
        Swal.fire('Error', 'Ha ocurrido un error al guardar', 'error')
      }
    }
  },
}
</script>

<style scoped>

</style>
